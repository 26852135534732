import { Col, Container, Stack,} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faFacebook, faLinkedin, faSquareInstagram, faSquareWhatsapp} from "@fortawesome/free-brands-svg-icons";
import "../assets/styles/test.scss";
import { Link } from "react-router-dom";
export function Test() {
  return (
    <div id="test" style={{height:"45rem", marginTop: "3rem"}}>
      <Container>
        <Col lg={{offset: 1, span: 6}}>
          <Stack gap={5}>
            <div>
              <Stack direction="horizontal" gap={3}>
                <FontAwesomeIcon icon={faSquareInstagram} className="iconContact" id="instagram"/> 
                <Link to="https://www.instagram.com/thiago25vieira/">instagram.com/thiago25vieira</Link>
              </Stack>
            </div>
            <div>
              <Stack direction="horizontal" gap={3}>
                <FontAwesomeIcon icon={faLinkedin} className="iconContact" id="linkedin" />
                <Link to="https://www.linkedin.com/in/thiago-vieira-prog/">linkedin.com/in/thiago-vieira-prog</Link>
              </Stack>
            </div>
            <div>
              <Stack direction="horizontal" gap={3}>
                <FontAwesomeIcon icon={faFacebook} className="iconContact" id="facebook" />
                <Link to="https://www.facebook.com/thiago25vieira">facebook.com/thiago25vieira</Link>
              </Stack>
            </div>
            <div>
              <Stack direction="horizontal" gap={3}>
                <FontAwesomeIcon icon={faSquareWhatsapp} className="iconContact" id="whatsApp" />
                <Link to="https://wa.me/5541997312045">(41)9 9731-2041</Link>
              </Stack>
            </div>
          </Stack>
        </Col>
      </Container>
    </div>
  );
}