import React from "react";
import "./style.scss";
import {Nav, Navbar, Container, Image} from "react-bootstrap";
import logo from "../../assets/images/logo-svg.svg"
export const Header: React.FC = () =>{
    return(
        <div id="header">
            <Navbar expand="lg" id="navBar">
                <Container>
                    <Navbar.Brand id="NavBrand"><Image id="logoNav" src={logo} style={{width: "8.2rem"}}/></Navbar.Brand>
                    <Nav className="me-auto">
                        <Nav.Link className="menuLink" href="/#/home">Inicial</Nav.Link>
                        <Nav.Link className="menuLink" href="/#/presentation">Apresentação</Nav.Link>
                        <Nav.Link className="menuLink" href="/#/projects">Projetos</Nav.Link>
                        <Nav.Link className="menuLink" href="/#/contacts">Contatos</Nav.Link>
                    </Nav>
                </Container>
            </Navbar>
        </div>
    )
}