import React from "react";
import "./style.scss";
import {Image, Container, Row, Col} from "react-bootstrap";
import qrcodeLinke from "../../assets/images/qrcode_linkedin.png";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin, faSquareGithub, faSquareInstagram, faSquareWhatsapp } from "@fortawesome/free-brands-svg-icons";
import qrCodeWhats from "../../assets/images/qrcode_whatsapp.png";
import qrCodeGit from "../../assets/images/qrcode_github.png";
import qrCodeFace from "../../assets/images/qrcode_facebook.png";
import qrcodeInsta from "../../assets/images/qrcode_instagram.png";

export const Footer: React.FC = () => {
    const styleCol = {
            marginTop: '0.7rem',
            marginBottom: '0.5rem',
            paddingLeft: 'auto',
            paddingRight: 'auto',
            }
    return(
        <div id="footer">
            <Container>
                <Row id="contactsFooter">
                    <Col style={styleCol} lg={{offset: 1}}>
                        <Image className="imageQrCode" src={qrcodeLinke} style={{width: "5rem", height: "5rem"}} />
                        <h6>
                            <FontAwesomeIcon icon={faLinkedin} className="iconContact" id="linkedin" />
                            <Link to="https://www.linkedin.com/in/thiago-vieira-prog/">Linkedin</Link>
                        </h6>
                    </Col>
                    <Col style={styleCol}>
                        <Image className="imageQrCode" src={qrCodeGit} style={{width: "5rem", height: "5rem"}} />
                        <h6>
                            <FontAwesomeIcon icon={faSquareGithub} className="iconContact" id="gitHub" />
                            <Link to="https://github.com/Thiagocod">GitHub</Link>
                        </h6>
                    </Col>
                    <Col style={styleCol}>
                        <Image className="imageQrCode" src={qrCodeWhats} style={{width: "5rem", height: "5rem"}} />
                        <h6>
                            <FontAwesomeIcon icon={faSquareWhatsapp} className="iconContact" id="whatsApp" />
                            <Link to="https://wa.me/5541997312045">WhatsApp</Link>
                        </h6>
                    </Col>
                    <Col style={styleCol}>
                        <Image className="imageQrCode" src={qrCodeFace} style={{width: "5rem", height: "5rem"}} />
                        <h6>
                            <FontAwesomeIcon icon={faFacebook} className="iconContact" id="facebook" />
                            <Link to="https://www.facebook.com/thiago25vieira">Facebook</Link>
                        </h6>
                    </Col>
                    <Col style={styleCol}>
                        <Image className="imageQrCode" src={qrcodeInsta} style={{width: "5rem", height: "5rem"}} />
                        <h6>
                            <FontAwesomeIcon icon={faSquareInstagram} className="iconContact" id="instagram"/> 
                            <Link to="https://www.instagram.com/thiago25vieira/">Instagram</Link>
                        </h6>
                    </Col>
                </Row>
            </Container>
        </div>
    )
}