import { Spinner } from "react-bootstrap";
import "./styles.scss";
interface LoadingProps{
    hidden: string;
}
const Loading: React.FC <LoadingProps> = ({hidden}) => {
    const displayHidden = hidden;
    return(
        <div id="loading" style={{display: displayHidden}}>
            <div id="spinner">
                <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                </Spinner>
            </div>
        </div>
    )
}
export default Loading;