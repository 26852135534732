import React, { useEffect, useState } from "react";
import {Row, Col, Stack, Button, Image} from "react-bootstrap";
import profilePhoto from "../../assets/images/profilePhoto.svg";
import Loading from "../Loading/Loading";

const HomeBody: React.FC = () =>{
    const [removeLoading, setRemoveLoading] = useState("block");
    const [showBody, setShowBody] = useState("none");
    const imageLoading = () =>{
        return(
            <>
                <Image src={profilePhoto} style={{width: "20rem"}} thumbnail id="profilePhoto" />
            </>
        )
    }
    useEffect(()=>{
        if(imageLoading()){
            const showBody = () => {
                setShowBody("block");
                setRemoveLoading("none");
            }
            setTimeout(showBody, 700);
        }
    },[]);

    return(
        <>
                <div style={{display: showBody}}>
                    <Row>
                        <Col lg={{offset: 3, span: 8}} sm={12} none={12} >
                            {imageLoading()} 
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={{offset: 3, span: 4}}>
                            <h3>Thiago Vieira</h3>
                            <h4>Engenheiro de software</h4>
                            <h4>UniCesumar-PR</h4>
                        </Col>
                    </Row>
                    <Row id="menuSmart">
                        <Col>
                            <Stack gap={3} id="stackMenu">
                                <Button href="/#/presentation" variant="success">Apresentação</Button>
                                <Button href="/#/projects" variant="info">Projetos</Button>
                                <Button href="/#/contacts">Contato</Button>
                            </Stack>
                        </Col>
                    </Row>
                </div>
                <Loading hidden={removeLoading}/>;
        </>
    )
}

export default HomeBody;