import { Col, Row, Stack} from "react-bootstrap";
import "../assets/styles/presentation.scss";
import { FooterMobile } from "../Components/FooterMobile/FooterMobile";

export function Presentation () {
    return(
        <div id="presentation">
            <main>
                <Row>
                    <Col lg={{offset: 1, span: 9}} sm={10} none={10}>
                        <Stack gap={3}>    
                            <h3>Thiago Vieira</h3>
                            <h4>Bacharelado Engenharia de software</h4>
                            <p>Tenho 35 anos, apaixonado por programação e tecnologia, sou formado pela Unicesumar - Maringa, 
                                Além da minha formação tenho diversos cursos na area e outras areas também de meu interesse, 
                                tenho experiencia tanto com desenvolvimento quanto suporte de sites como especialista em suporte.</p>
                            <h5>Seja bem vindo ao meu site e meu labolatorio de testes!</h5>
                        </Stack>
                    </Col>
                </Row>
                <FooterMobile />
            </main>
        </div>
    )
}