import { Col, Container, Row, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faLinkedin, faSquareInstagram, faSquareWhatsapp} from "@fortawesome/free-brands-svg-icons";
import "../assets/styles/contacts.scss";
import { Link } from "react-router-dom";
import { FooterMobile } from "../Components/FooterMobile/FooterMobile";


export function Contacts () {
    return(
        <div id="contacts">
            <main>
                <Container>
                    <Row>
                        <Col lg={{offset: 1, span: 8}}>
                            <h1>Contatos!</h1>
                        </Col>
                    </Row>
                    <Row>
                        <Col id="bodyContacts" lg={{offset: 1, span: 8}}>
                            <Stack gap={5}>
                                <div>
                                <Stack direction="horizontal" gap={3}>
                                    <Link to="https://www.instagram.com/thiago25vieira/"> <FontAwesomeIcon icon={faSquareInstagram} className="iconContact" id="instagram"/> </Link>
                                    <Link to="https://www.instagram.com/thiago25vieira/">instagram.com/thiago25vieira</Link>
                                </Stack>
                                </div>
                                <div>
                                <Stack direction="horizontal" gap={3}>
                                    <Link to="https://www.linkedin.com/in/thiago-vieira-prog/"><FontAwesomeIcon icon={faLinkedin} className="iconContact" id="linkedin" /> </Link>
                                    <Link to="https://www.linkedin.com/in/thiago-vieira-prog/">linkedin.com/in/thiago-vieira-prog</Link>
                                </Stack>
                                </div>
                                <div>
                                <Stack direction="horizontal" gap={3}>
                                    <Link to="https://www.facebook.com/thiago25vieira"><FontAwesomeIcon icon={faFacebook} className="iconContact" id="facebook" /> </Link>
                                    <Link to="https://www.facebook.com/thiago25vieira">facebook.com/thiago25vieira</Link>
                                </Stack>
                                </div>
                                <div>
                                <Stack direction="horizontal" gap={3}>
                                    <Link to="https://wa.me/5541997312045"><FontAwesomeIcon icon={faSquareWhatsapp} className="iconContact" id="whatsApp" /> </Link>
                                    <Link to="https://wa.me/5541997312045">(41)9 9731-2041</Link>
                                </Stack>
                                </div>
                            </Stack>
                        </Col>
                    </Row>
                </Container>
                <FooterMobile />
            </main>
        </div>
    )
}