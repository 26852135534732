import { Col, Container, Row, Table, Stack } from "react-bootstrap"
import "../assets/styles/projects.scss"
import { Link } from "react-router-dom"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faSquareGithub } from "@fortawesome/free-brands-svg-icons"
import { faArrowRight } from "@fortawesome/free-solid-svg-icons"
import { FooterMobile } from "../Components/FooterMobile/FooterMobile"
export function Projects () {
    return(
        <div id="projects">
            <main>
                <Container>
                    <Stack gap={2}>
                        <div>
                            <Row><h1>Projetos de software!</h1></Row>
                        </div>
                        <div>
                            <Row>
                                <h4>Mini Projetos</h4>
                            </Row>
                            <Row>
                                <Col> 
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Nome do projeto</th>
                                                <th>Link para acesso</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>01</th>
                                                <th>Web Mobile</th>
                                                <th><Link to="https://thiagocod.github.io/MobileWeb/">Clique aqui!</Link></th>
                                            </tr>
                                            <tr>
                                                <th>02</th>
                                                <th>Texto Animado!</th>
                                                <th><Link to="https://thiagocod.github.io/textAnimate/">Clique aqui!</Link></th>
                                            </tr>
                                            <tr>
                                                <th>03</th>
                                                <th>Hover Board</th>
                                                <th><Link to="https://thiagocod.github.io/hoverboard/">Clique aqui!</Link></th>
                                            </tr>
                                            <tr>
                                                <th>04</th>
                                                <th>Notas autoAdesivas</th>
                                                <th><Link to="https://thiagocod.github.io/NotesApp/">Clique aqui!</Link></th>
                                            </tr>
                                            <tr>
                                                <th>05</th>
                                                <th>Faq</th>
                                                <th><Link to="https://thiagocod.github.io/faq-simple/">Clique aqui!</Link></th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <h4>Projetos</h4>
                            </Row>
                            <Row>
                                <Col> 
                                    <Table>
                                        <thead>
                                            <tr>
                                                <th></th>
                                                <th>Nome do projeto</th>
                                                <th>Link para acesso</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <th>01</th>
                                                <th>Pergunte-me</th>
                                                <th><Link to="https://letmeask-d10d5.web.app/">Clique aqui!</Link></th>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={{offset: 6, span: 6}} style={{textAlign: "right"}}>
                                    <Stack direction="horizontal" gap={2}>
                                        <h6>
                                            Clique no icone para acessar os códigos
                                        </h6>
                                        <span>
                                            <FontAwesomeIcon icon={faArrowRight} id="arrowRight" />
                                        </span>
                                        <Link id="gitHub" to="https://github.com/Thiagocod"><FontAwesomeIcon icon={faSquareGithub} className="iconContact" /></Link>
                                    </Stack>
                                </Col>
                            </Row>
                        </div>
                    </Stack>   
                </Container>
                <FooterMobile />
            </main>
        </div>
    )
}