import {HashRouter as Router, Route, Routes} from "react-router-dom";
//pages
import {Home} from "./pages/Home";
import { Presentation } from "./pages/Presentation";
import { Projects } from "./pages/Projects";
import { Contacts } from "./pages/Contacts";
//pages tests
import {Test} from "./pages/Test";
//Dependence
import 'bootstrap/dist/css/bootstrap.min.css';
//Components
import { Header } from "./Components/Header/Header"
import { Footer } from "./Components/Footer/Footer";

function App() {
  return (
    <Router>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/home" element={<Home />} />
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/contacts" element={<Contacts />} />
        <Route path="/test" element={<Test />} />
      </Routes>
      <Footer />
    </Router>
      ) 
}

export default App;