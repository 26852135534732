import "../assets/styles/home.scss";
import HomeBody from "../Components/HomeBody/HomeBody";


export function Home (){
    return(
        <div id="home">
            <main>
                <HomeBody />
            </main>
        </div>
    )
}