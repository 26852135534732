import React from "react";
import "./style.scss";
import { Button, Col, Container, Row, Stack } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAddressBook, faBookOpen, faDiagramProject, faHouse } from "@fortawesome/free-solid-svg-icons";

export const FooterMobile: React.FC = () => {

    return(
        <div id="footerMobile">
            <Container>
                <Row id="row">
                    <Col id="col">
                        <Stack direction="horizontal" gap={4} id="stack">
                            <Button href="/#/home" className="btnMenuFooter" variant="success"><FontAwesomeIcon icon={faHouse} /></Button>
                            <Button href="/#/presentation" className="btnMenuFooter" variant="warning"><FontAwesomeIcon icon={faBookOpen} /></Button>
                            <Button href="/#/projects" className="btnMenuFooter" variant="info"><FontAwesomeIcon icon={faDiagramProject} /></Button>
                            <Button href="/#/contacts" className="btnMenuFooter"><FontAwesomeIcon icon={faAddressBook} /></Button>
                        </Stack>
                    </Col>
                </Row>
            </Container>
        </div>
    )

}